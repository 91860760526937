import { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

function Viewer({ pdfFile }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const changePage = (direction) => {
    if (direction === "prev" && pageNumber > 1) setPageNumber(pageNumber - 1);
    if (direction === "next" && pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const calculateWidth = () => {
    if (windowWidth <= 640) return windowWidth * 0.75; // For mobile screens
    if (windowWidth <= 1024) return windowWidth * 0.75; // For tablets
    return 700; // For desktops
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = pdfFile.split('/').pop();
    link.click();
  };

  return (
    <div className="flex flex-col items-center justify-center mt-8 space-y-6">
      {/* PDF Container */}
      <div className="w-full lg:w-3/4 xl:w-2/3 bg-gray-50 p-6 shadow-lg rounded-lg">
        <div className="w-full bg-white p-4 border border-gray-200 rounded-lg shadow-inner flex justify-center">
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            className="flex justify-center"
          >
            <Page
              pageNumber={pageNumber}
              width={calculateWidth()}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              className="w-full h-auto"
            />
          </Document>
        </div>

        {/* Navigation Controls */}
        <div className="w-full flex justify-center items-center space-x-4 mt-4">
          <IoIosArrowBack
            className={`text-2xl cursor-pointer ${pageNumber === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => changePage("prev")}
          />
          <span className="text-gray-600 bg-gray-200 px-4 py-2 rounded-lg">
            Page {pageNumber} of {numPages}
          </span>
          <IoIosArrowForward
            className={`text-2xl cursor-pointer ${pageNumber === numPages ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => changePage("next")}
          />
        </div>

        {/* Download Button */}
        <div className="w-full flex justify-center mt-6">
          <button
            onClick={handleDownload}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
}

export default Viewer;
