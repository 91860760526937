import React from 'react';
import Pillars from "./Pillars";
import Vernacular from "./Vernacular";
import Assessment from "./Assessment";
import Slogan from "./Slogan";

const Hybrid = () => {
  return (
    <div className='relative'>
  <div className='absolute inset-0 bg-gradient-to-b from-[#f7f9fc] to-[#D6E4FF] rounded-b-3xl' style={{ height: '93%', zIndex: -1 }} />
  <Vernacular />
  <Assessment />
  <div className='flex flex-col justify-center items-center px-4 py-4 mt-6 mb-16'>
            <h1 className='text-4xl py-10 text-center font-bold'>Recognitions</h1>
                <div className='flex justify-center items-center gap-12'>
                    
                    <div className='bg-white shadow-lg rounded-lg p-4 flex flex-col items-center'>
                        <img src="https://images.schooglink.com/image/website/skoch_silver_award.png" alt='SKOCH Silver Award' className='max-w-full h-auto rounded-t-lg' />
                        <p className='mt-4 text-center font-medium'><strong>SKOCH Silver Award :</strong>  Annupur smart classes</p>
                    </div>
                    <div className='bg-white shadow-lg rounded-lg p-4 flex flex-col items-center'>
                        <img src="https://images.schooglink.com/image/website/prime_minister_award.png" alt='Prime Minister Award' className='max-w-full h-auto rounded-t-lg' />
                        <p className='mt-4 text-sm text-center font-medium'><strong>Prime Minister's Award </strong> <br /> for Excellence in Public Administration 2020 <br /> Innovation-General Category (3rd rank)</p>
                    </div>
                </div>
        </div>

  <Slogan />
  <Pillars />
</div>

  
  )
}

export default Hybrid