import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import Viewer from './Viewer';
import Awards from './Awards';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import graph2024 from '../assets/highlights_page/graph2024.png';
import graph2023 from '../assets/highlights_page/graph2023.png';
import graph2022 from '../assets/highlights_page/graph2022.png';
import topper1 from '../assets/topper1.png';
import topper2 from '../assets/topper2.png';
import topper3 from '../assets/topper3.png';
import impact_report_2023_lite from '../assets/pdfs/impact_report_2023_lite.pdf';

const testimonials = [
  {
    name: "Shivam Kumar",
    location: "Bihar State Board Rank 08 Class X",
    videoUrl: "https://www.youtube.com/embed/FsAAT5UnzJE?modestbranding=1&showinfo=0&rel=0",
  },
  {
    name: "Afreen Khatun",
    location: "Madhya Pradesh State Board District Anuppur Rank 02 Class X",
    videoUrl: "https://www.youtube.com/embed/gT6b4eXQxJ0?modestbranding=1&showinfo=0&rel=0",
  },
  {
    name: "Sushant Kumar Das",
    location: "Jharkhand State Board Block Sonahatu Rank 01 Class X",
    videoUrl: "https://www.youtube.com/embed/r06xuQWI56s?modestbranding=1&showinfo=0&rel=0",
  },
];

const toppers = [
  {
    name: "Shivam Kumar",
    location: "Bihar",
    score: "BSEB X-95.4%",
    image: topper1
  },
  {
    name: "Afreen Khatun",
    location: "Madhya Pradesh",
    score: "MPBSE X-97.75%",
    image: topper2
  },
  {
    name: "Sushant Kumar Das",
    location: "Jharkhand",
    score: "JAC X-93.80%",
    image: topper3
  }
];

const ImpactPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    { src: graph2024, alt: "Schooglink Grassroots Performance 2024" },
    { src: graph2023, alt: "Schooglink Grassroots Performance 2023" },
    { src: graph2022, alt: "Schooglink Grassroots Performance 2022" },
  ];

  // Swipe handler
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setActiveIndex((prevIndex) => (prevIndex + 1) % images.length),
    onSwipedRight: () => setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Slide left function
  const slideLeft = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Slide right function
  const slideRight = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className='mt-12 px-4 py-4'>
      <div className="flex flex-col items-center justify-center p-4 mt-8">
        {/* Heading */}
        <h1 className="text-2xl font-bold text-center mb-6">
          Schooglink Grassroot Performance
        </h1>

        {/* Images Container for Mobile Screens */}
        <div className="md:hidden flex flex-col items-center" {...swipeHandlers}>
          {/* Active Image */}
          <div className="relative w-full h-auto">
            <img
              src={images[activeIndex].src}
              alt={images[activeIndex].alt}
              className="w-full h-auto max-h-[252px] object-contain"
            />
            {/* Navigation Arrows for Mobile Only */}
            <button 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-2 rounded-full shadow-lg md:hidden"
              onClick={slideLeft}
            >
              <MdChevronLeft size={24} />
            </button>
            <button 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-2 rounded-full shadow-lg md:hidden"
              onClick={slideRight}
            >
              <MdChevronRight size={24} />
            </button>
          </div>
          {/* Dots for navigation */}
          <div className="flex justify-center mt-2 space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === activeIndex ? 'bg-blue-500' : 'bg-gray-400'
                }`}
                onClick={() => setActiveIndex(index)}
              ></button>
            ))}
          </div>
        </div>

        {/* Images Container for Larger Screens */}
        <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div key={index} className="relative w-full h-auto">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-auto max-h-[260px] object-contain"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Toppers Section */}
      <div className="flex flex-col items-center mb-12 mt-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Toppers</h2>
        <div className="flex flex-wrap justify-center overflow-hidden gap-6 py-4">
          {toppers.map((topper, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-32 sm:w-40 md:w-48 lg:w-64 h-auto p-2"
            >
              <img
                src={topper.image}
                alt={topper.name}
                className="w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 object-cover mb-2"
              />
              <h3 className="text-base font-semibold text-center">{topper.name}</h3>
              <p className="text-sm text-gray-600 text-center">{topper.location}</p>
              <p className="text-sm text-gray-600 text-center">{topper.score}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Testimonial Section */}
      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-bold mb-8 text-center">Testimonial</h2>
        <div className="flex flex-col lg:flex-row justify-center gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-80 h-72 p-4 "
            >
              <div className="aspect-w-16 aspect-h-9 w-full ">
                <iframe
                  className="w-full h-full rounded-lg border"
                  src={testimonial.videoUrl}
                  title={testimonial.name}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <h3 className="mt-4 text-lg font-semibold">{testimonial.name}</h3>
              <p className="text-gray-600 text-center mb-2">{testimonial.location}</p>
            </div>
          ))}
        </div>
      </div>

      {/* PDF Viewer */}
      <Viewer pdfFile={impact_report_2023_lite} />

      <Awards />
    </div>
  );
};

export default ImpactPage;
