import React from 'react';
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai'; // Updated icons
import contact_image from '../assets/contact_image.png';

const ContactUs = () => {
  return (
    <div className="mt-12 flex flex-col px-4 py-8 justify-center items-center gap-12 w-[80%] mx-auto mt-24">
      
      {/* Contact Image and Text Section */}
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between text-center w-full">
        <div className="lg:w-1/2 px-4">
          <img src={contact_image} alt="Contact" className="mx-auto max-w-full h-auto" />
        </div>

        <div className="lg:w-1/2 flex flex-col items-center px-4">
          <h1 className="text-3xl lg:text-4xl px-4 py-6 font-bold">We would like to hear from you!</h1>
          <p className="text-lg lg:text-xl font-bold text-gray-700 leading-relaxed">
            Do you have any feedback about our products or suggestions you'd like to share with us?
          </p>

          <div className="flex flex-col items-center w-full mt-8">
            <h1 className="text-3xl lg:text-4xl px-4 py-6 font-bold">Contact Us</h1>
            <div className="w-full max-w-md bg-gray-100 px-10 py-8 rounded-xl shadow-lg">
              <p className="flex items-center justify-center text-lg lg:text-xl mb-4">
                <AiFillPhone className="mr-2" /> +91 80695 10555
              </p>
              <p className="flex items-center justify-center text-lg lg:text-xl">
                <AiOutlineMail className="mr-2" /> contact@schooglink.com
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Co-ordinates Section */}
      <div className="px-4 py-12 w-full">
        <h1 className="text-3xl lg:text-4xl font-bold text-center mb-8">Our Co-ordinates</h1>
        <div className="flex flex-col lg:flex-row lg:justify-center gap-8 items-center">
          
          {/* Bengaluru Card */}
          <div className="w-full max-w-lg bg-gray-100 px-10 py-6 rounded-xl shadow-md h-48">
            <h1 className="text-2xl lg:text-3xl font-bold mb-4">Bengaluru</h1>
            <p className="text-md lg:text-lg font-bold text-gray-700 mb-4 leading-relaxed">
              Ramasagara Village, Electronic City Phase - 2, Bengaluru, Karnataka 560100
            </p>
            <p className="flex items-center text-lg">
              <AiFillPhone className="mr-2" /> +91-6203968601
            </p>
          </div>
          
          {/* Patna Card */}
          <div className="w-full max-w-lg bg-gray-100 px-10 py-6 rounded-xl shadow-md h-48">
            <h1 className="text-2xl lg:text-3xl font-bold mb-4">Patna</h1>
            <p className="text-md lg:text-lg font-bold text-gray-700 mb-4 leading-relaxed">
              New Punaichak, Rahbanshi Nagar, Patna, Bihar
            </p>
            <p className="flex items-center text-lg">
              <AiFillPhone className="mr-2" /> +91-6203968602
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
