import React from 'react';


const Pillars = () => {
  return (
    <div className='mt-8 ml-4 mr-4 sm:ml-8 sm:mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32 xl:ml-64 xl:mr-64'>
        <div className='grid grid-cols-3 grid-rows-1 gap-4'>
        <a href='vernacular'>
          <img src="https://images.schooglink.com/image/website/vernacular_content.png" alt='Vernacular Content' className='w-full h-full object-cover' />
        </a>
        <a href='smart'>
            <img src="https://images.schooglink.com/image/website/hybrid_technology.png" alt='Hybrid Technology' className='w-full h-full object-cover' />
        </a>
        <a href='assessment'>    
            <img src="https://images.schooglink.com/image/website/assessment.png" alt='Formative & Summative Assesment' className='w-full h-full object-cover' />
        </a>
        </div>
        <div className='grid grid-cols-3 grid-rows-1 gap-4 place-items-center'>
            <p className='text-xs sm:text-lg font-bold '>Vernacular Content</p>
            <p className='text-xs sm:text-lg font-bold '>Hybrid Technology</p>
            <p className='text-xs sm:text-lg font-bold   text-center'>Formative & Summative Assesment</p>
        </div>
    </div>
  )
}

export default Pillars