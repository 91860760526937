import React  from 'react';
// import { useEffect } from 'react';
import {pdfjs } from 'react-pdf';
// import { Document, Page } from 'react-pdf';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// import Faq  from "./Faq";
// import {faqlist1} from "./FaqList1";
// import {faqlist2} from "./FaqList2";
// import { FaWhatsapp, FaPhone } from "react-icons/fa";
// import { MdOutlineEmail } from "react-icons/md";
// import image1 from '../assets/Image-1.jpg';
// import image2 from '../assets/Image-2.jpg';
// import image3 from '../assets/Image-3.jpg';
// import image4 from '../assets/Image-4.jpg';
// import image5 from '../assets/Image-5.jpg';
import smart_school_handbook_2024_lite from '../assets/pdfs/smart_school_handbook_2024_lite.pdf';
import Viewer from './Viewer';


const testimonials = [
  {
    name: "Surya Kumari (Teacher)",
    location: "JAC X, SS High School, Sonahatu",
    videoUrl: "https://www.youtube.com/embed/tiLxZ8gQehY",
  },
  {
    name: "Prasit Kumar Das (Teacher)",
    location: "Kanya Uchchya Madhyamik School, Anuppur",
    videoUrl: "https://www.youtube.com/embed/QKKe1CLunnQ?si=81GrhSn5F2J1Vn1R",
  },
  {
    name: "Neha Sahu",
    location: "JAC X, Balika High School, Patrahatu",
    videoUrl: "https://www.youtube.com/embed/drTG0K3C7yU",
  },
];

const data = [
  {
    id: 1,
    img: 'https://images.schooglink.com/image/website/news1.png',
  },
  {
    id: 2,
    img: 'https://images.schooglink.com/image/website/news3.png',
  },
  {
    id: 3,
    img: 'https://images.schooglink.com/image/website/news4.png',
  },
  {
    id: 4,
    img: 'https://images.schooglink.com/image/website/news5.png',
  },
  {
    id: 5,
    img: 'https://images.schooglink.com/image/website/news6.png',
  },
  {
    id: 6,
    img: 'https://images.schooglink.com/image/website/news8.png',
  },
  {
    id: 7,
    img: 'https://images.schooglink.com/image/website/news9.png',
  },
  ]

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Smartschools = () => {
    // const [cards1] = useState(faqlist1);
    // const [cards2] = useState(faqlist2);
    // const [nav, setNav] = useState(false);
    // const handleNav = () => {
    //     setNav(!nav);
    // };

    // const [numPages, setNumPages] = useState();
    // const [pageNumber, setPageNumber] = useState(1);

    // function onDocumentLoadSuccess({ numPages }) {
    //   setNumPages(numPages);
    // }

    const slideRight = () => {
      var slider = document.getElementById('slider');
      slider.scrollLeft += 280;
    }

    const slideLeft = () => {
      var slider = document.getElementById('slider');
      slider.scrollLeft -= 280;
    }
    
  return (
    <div className='lex flex-col justfy-center itemfs-center px-4 mt-20'>
        <div className="flex flex-wrap max-w-screen-lg mx-auto mb-8">
    <div className='flex flex-wrap px-4 py-4'>
        <div className="w-full md:w-1/2 h-auto">
            <div className="relative w-full h-0 pb-[56.25%] rounded-lg overflow-hidden">
                <iframe 
                    className="absolute top-0 left-0 w-full h-full rounded-lg" 
                    src="https://www.youtube.com/embed/oPKPQch0sAs?si=IcIP4GDP5tHRSMTN" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen>
                </iframe>
            </div>
        </div>

        <div className='flex flex-col justify-center w-full md:w-1/2 bg-gray-200 px-8 py-6 rounded-lg'>
            <p className='text-justify text-sm md:text-base font-bold'>
                Since our first ever foray into setting up a smart classroom in Kanya Anuppur School in 2019, 
                we have come a long way in building the Schooglink Smart School solution to strengthen & spread our reach across India. 
                Our hybrid smart class model has been deployed in 120+ classrooms across four states so far, covering  
                U.P, M.P, Bihar & Jharkhand, across multiple districts, schools, and constituencies.
            </p>
        </div>
    </div>
</div>


<div className='flex flex-col justify-center items-center px-4 py-4 mt-6 mb-16'>
            <h1 className='text-4xl py-10 text-center font-bold'>Recognitions</h1>
                <div className='flex justify-center items-center gap-12'>
                    
                    <div className='bg-white shadow-lg rounded-lg p-4 flex flex-col items-center'>
                        <img src="https://images.schooglink.com/image/website/skoch_silver_award.png" alt='SKOCH Silver Award' className='max-w-full h-auto rounded-t-lg' />
                        <p className='mt-4 text-center font-medium'><strong>SKOCH Silver Award :</strong>  Annupur smart classes</p>
                    </div>
                    <div className='bg-white shadow-lg rounded-lg p-4 flex flex-col items-center'>
                        <img src="https://images.schooglink.com/image/website/prime_minister_award.png" alt='Prime Minister Award' className='max-w-full h-auto rounded-t-lg' />
                        <p className='mt-4 text-sm text-center font-medium'><strong>Prime Minister's Award </strong> <br /> for Excellence in Public Administration 2020 <br /> Innovation-General Category (3rd rank)</p>
                    </div>
                </div>
        </div>


        <div className="flex flex-wrap max-w-screen-lg mx-auto mb-8">
    <div className='flex flex-col md:flex-row flex-wrap px-4 py-4'>
        <img src="https://images.schooglink.com/image/website/madhya_pradesh_smart_class.png" alt='Smart Schools' className='w-full md:w-1/2 h-auto object-cover' />
        <div className='flex flex-col justify-center w-full md:w-1/2 bg-blue-100 px-16 py-6 object-cover rounded-lg'>
            <h1 className='text-2xl font-bold'> Madhya Pradesh</h1>
            <p className='text-justify text-sm md:text-base font-bold mb-4'> Schooglink Smart Schools, Anuppur</p>
            <p className='text-justify text-sm md:text-base'> Achieving an average 10.45% improvement in the class X MPBSE State Board Exams 2020</p>
        </div>
    </div>
</div>

<div className="flex flex-wrap max-w-screen-lg mx-auto mb-8">
    <div className='flex flex-col-reverse md:flex-row flex-wrap px-4 py-4'>
        <div className='flex flex-col justify-center w-full md:w-1/2 bg-green-100 px-16 py-6 object-cover rounded-lg'>
            <h1 className='text-2xl font-bold'> Jharkhand </h1>
            <p className='text-justify text-sm md:text-base font-bold'> Schooglink Smart Schools, Silli</p>
            <p className='mb-2'>Achieving block level 1st rank with 93.8% marks in class X JAC State Board Exams 2024 </p>
            <p className='text-justify text-sm md:text-base font-bold'> Aditya Birla High School, Muri, Ranchi </p>
            <p className='text-justify text-sm md:text-base font-bold'>Schooglink Smart Schools, Gomiya, Bokaro</p>
        </div>
        <img src="https://images.schooglink.com/image/website/jharkhand_smart_class.png" alt='Smart Schools' className='w-full md:w-1/2 h-auto object-cover' />
    </div>
</div>

<div className="flex flex-wrap max-w-screen-lg mx-auto mb-8">
    <div className='flex flex-col md:flex-row flex-wrap px-4 py-4'>
        <img src="https://images.schooglink.com/image/website/bihar_smart_class.png" alt='Smart Schools' className='w-full md:w-1/2 h-auto object-cover' />
        <div className='flex flex-col justify-center w-full md:w-1/2 bg-yellow-100 px-16 py-6 object-cover rounded-lg'>
            <h1 className='text-2xl font-bold'> Bihar </h1>
            <p className='text-justify text-sm md:text-base font-bold mb-4'> Schooglink Smart Schools, Gaya</p>
            <p className='text-justify text-sm md:text-base'> Achieving State Ranks in class X BSEB state board exams year on year, with a significant improvement in school attendance.</p>
        </div>
    </div>
</div>

<div className="flex flex-wrap max-w-screen-lg mx-auto mb-8">
    <div className='flex flex-col-reverse md:flex-row flex-wrap px-4 py-4'>
        <div className='flex flex-col justify-center w-full md:w-1/2 bg-pink-100 px-16 py-6 object-cover rounded-lg'>
            <h1 className='text-2xl font-bold'> Uttar Pradesh </h1>
            <p className='text-justify text-sm md:text-base font-bold mb-4'> Schooglink Smart School</p>
            <p className='text-justify text-sm md:text-base'> Ram Janki Inter College, IIT Kanpur</p>
        </div>
        <img src="https://images.schooglink.com/image/website/uttar_pradesh_smart_class.png" alt='Smart Schools' className='w-full md:w-1/2 h-auto object-cover' />
    </div>
</div>
        <div className="flex flex-col items-center">
  <h2 className="text-3xl font-bold mb-8 text-center">Testimonial</h2>
  <div className="flex flex-col lg:flex-row justify-center gap-8">
    {testimonials.map((testimonial, index) => (
      <div
        key={index}
        className="flex flex-col items-center w-80 h-72 p-4"
      >
        <div className="aspect-w-16 aspect-h-9 w-full">
          <iframe
            className="w-full h-full rounded-lg border"
            src={`${testimonial.videoUrl}?modestbranding=1&showinfo=0`}
            title={testimonial.name}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <h3 className="mt-4 text-lg font-semibold">{testimonial.name}</h3>
        <p className="text-gray-600 text-center mb-2">{testimonial.location}</p>
      </div>
    ))}
  </div>
</div>


        

        <div className=' flex flex-col justify-center items-center px-4 py-4 mt-10 '>
            <h1 className='text-3xl py-10 font-bold'> Media Coverage </h1>
        </div>
        <div className='relative flex items-center'>
            <MdChevronLeft onClick={slideLeft} size={40} className='opacity-50 cursor-pointer hover:opacity-100' />
            <div id='slider' className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                {data.map((item) => (
                    <img src={item.img} alt='Media Coverage' className='w-[280px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300' />                            
                ))}
            </div>
            <MdChevronRight onClick={slideRight} size={40} className='opacity-50 cursor-pointer hover:opacity-100'/>
        </div>



        <div className='flex flex-col items-center justify-center lg:mt-24 space-y-10'>
  <h1 className='text-4xl py-8 text-center font-extrabold text-gray-900 tracking-tight leading-tight'>
    Schooglink Smart Schools Handbook
  </h1>

  <Viewer pdfFile={smart_school_handbook_2024_lite} />


  {/* old pdf implemetation  */}
            
  {/* <div className='w-full lg:w-3/4 xl:w-2/3 flex flex-col items-center bg-gray-50 py-10 px-6 sm:px-8 md:px-12 shadow-lg rounded-lg'>
    <div className="w-full max-w-full md:max-w-[85%] lg:max-w-[75%] flex justify-center overflow-hidden border border-gray-200 rounded-lg">
      <Document 
        file="https://website-odwgmaamwq-uc.a.run.app/smart_school_handbook_2024_lite.pdf" 
        onLoadSuccess={onDocumentLoadSuccess}
        className="w-full flex justify-center"
        key={window.innerWidth}  // Added to force re-render on resize
      >
        <Page 
          key={pageNumber}  // Ensure re-render on page change
          pageNumber={pageNumber} 
          renderTextLayer={false} 
          renderAnnotationLayer={false} 
          className="w-full h-auto" 
          width={typeof window !== "undefined" && window.innerWidth < 768 ? window.innerWidth * 0.85 : 700}  // Adjust width for first load
        />
      </Document>
    </div>

    <div className='py-4 text-sm sm:text-base text-gray-600 flex items-center justify-center space-x-4'>
      <button 
        onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)} 
        disabled={pageNumber === 1} 
        className={`px-3 py-1 bg-gray-200 rounded-lg text-gray-700 font-medium transition-all duration-200 hover:bg-gray-300 ${pageNumber === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
      >
        &lt;
      </button>

      <span>Page {pageNumber} of {20}</span>

      <button 
        onClick={() => setPageNumber(pageNumber < 20 ? pageNumber + 1 : 20)} 
        disabled={pageNumber === 20} 
        className={`px-3 py-1 bg-gray-200 rounded-lg text-gray-700 font-medium transition-all duration-200 hover:bg-gray-300 ${pageNumber === 20 ? 'cursor-not-allowed opacity-50' : ''}`}
      >
        &gt;
      </button>
    </div>

    <a 
      href="https://website-odwgmaamwq-uc.a.run.app/smart_school_handbook_2024_lite.pdf" 
      target="_blank" // Open in new tab
      rel="noopener noreferrer" // Security feature
      className='mt-6 py-3 px-6 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-all duration-200 text-center'
    >
     Download
    </a>
  </div> */}

  {/* Auto Reload on Screen Resize */}
  {/* {useEffect(() => {
    const handleResize = () => {
      window.location.reload();  // Reloads the page when screen size changes
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);  // Cleanup event listener on unmount
    };
  }, [])} */}
</div>
    </div>
  )
}

export default Smartschools