import React, { useState } from 'react';
import BiharStartupAward from '../assets/BiharStartupAward.png';
import BiharStartupAwardCertificate from '../assets/BiharStartupAwardCertificate.png';
import gesa_badges22_finalistCertificate from '../assets/awards/gesa_badges22_finalist.png';
import holoniq_100Certificate from '../assets/awards/holoniq_100.png';
import hp_awardCertificate from '../assets/awards/hp_award.png';
import iim_calcuttaCertificate from '../assets/awards/iim_calcutta.png';
import skochCertificate from '../assets/awards/skoch.png';
import skoch_silver_awardCertificate from '../assets/awards/skoch_silver_award.png';
import smart_fifty_awards_by_iim_calcuttaCertificate from '../assets/awards/smart_fifty_awards_by_iim_calcutta.png';
import smc_select_2017Certificate from '../assets/awards/smc_select_2017.png';
import upstart_22_awardCertificate from '../assets/awards/upstart_22_award.png';
import DLabsIndianSchoolofBusiness  from '../assets/awards/DLabsIndianSchoolofBusiness.png';

const Awards = () => {
  const [currentIndex, setCurrentIndex] = useState(null);

  // Certificates mapping
  const certificates = [
    { name: "Bihar Startup Award", src: BiharStartupAwardCertificate },
    { name: "Upstart 22 Award", src: upstart_22_awardCertificate },
    { name: "Holon IQ", src: holoniq_100Certificate },
    { name: "GESA Badges", src: gesa_badges22_finalistCertificate },
    { name: "SKOCH Silver Award", src: skoch_silver_awardCertificate },
    { name: "SKOCH Group", src: skochCertificate },
    { name: "Smart Fifty", src: smart_fifty_awards_by_iim_calcuttaCertificate },
    { name: "Startup Master Class", src: smc_select_2017Certificate },
    { name: "Digital Trailblazer", src: hp_awardCertificate },
    { name: "IIM Calcutta", src: iim_calcuttaCertificate },
    { name: "DLabsIndianSchoolofBusiness ", src:DLabsIndianSchoolofBusiness  } // Added ISB Dlabs Prime Minister Award
  ];

  // Function to handle image click
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  // Function to close the certificate modal
  const handleCloseModal = () => {
    setCurrentIndex(null);
  };

  // Function to show next certificate
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % certificates.length);
  };

  // Function to show previous certificate
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + certificates.length) % certificates.length);
  };

  return (
    <div className='mt-24'>
      <p className='flex justify-center font-bold text-2xl sm:text-3xl'>Awards & Recognitions</p>
      <div className="flex flex-wrap justify-center mt-10 gap-4">
        {/* Bihar Startup Award */}
        <img 
          src={BiharStartupAward} 
          alt="Bihar Startup Award" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer"
          onClick={() => handleImageClick(0)} 
        />

        {/* Other Awards */}
        <img 
          src="https://images.schooglink.com/image/website/up_start_startup_internship_program_ecell_iit_kanpur.png" 
          alt="Upstart 22 Award Ecell IIT Kanpur" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(1)}
        />
        <img 
          src="https://images.schooglink.com/image/website/holon_iq.png" 
          alt="Holon IQ" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(2)}
        />
        <img 
          src="https://images.schooglink.com/image/website/gesawards_logo.png" 
          alt="GES Awards GESA Badges" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(3)}
        />
        <img 
          src="https://images.schooglink.com/image/website/skoch_awards_logo.png" 
          alt="SKOCH Silver Awards" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(4)}
        />
        <img 
          src="https://images.schooglink.com/image/website/skoch_group_logo.png" 
          alt="SKOCH Group" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(5)}
        />
        <img 
          src="https://images.schooglink.com/image/website/smart_fifty_logo.png" 
          alt="Smart Fifty" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(6)}
        />
        <img 
          src="https://images.schooglink.com/image/website/startup_master_class_logo.png" 
          alt="Startup Master Class SMC Select 2017" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(7)}
        />
        <img 
          src="https://images.schooglink.com/image/website/digital_trailblazer_logo.png" 
          alt="Digital Trailblazer" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(8)}
        />
        <img 
          src="https://images.schooglink.com/image/website/iim_calcutta_innovation_park_logo.png" 
          alt="IIM Calcutta Innovation Park" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(9)}
        />
         {/* ISB Dlabs Prime Minister Award */}
         <img 
          src="https://images.schooglink.com/image/website/isb_dlabs_logo.png" 
          alt="ISB Dlabs Prime Minister Award" 
          className="w-16 h-auto sm:w-20 md:w-24 lg:w-28 transform transition-transform duration-300 hover:scale-110 cursor-pointer" 
          onClick={() => handleImageClick(10)}  // Update index for new award
        />
      </div>

      {/* Modal to show certificates */}
      {currentIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative bg-white rounded-lg p-4 max-w-3xl w-full">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
              onClick={handleCloseModal}
            >
              &times;
            </button>

            {/* Left Arrow */}
            <button
              onClick={handlePrev}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-300 text-black rounded-full p-2"
            >
              &#9664;
            </button>

            {/* Certificate Image */}
            <img
              src={certificates[currentIndex].src}
              alt={`${certificates[currentIndex].name} Certificate`}
              className="w-full h-auto max-h-[80vh] object-contain"
            />

            <p className="mt-4 text-center font-medium">
              {certificates[currentIndex].name} Certificate
            </p>

            {/* Right Arrow */}
            <button
              onClick={handleNext}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-300 text-black rounded-full p-2"
            >
              &#9654;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Awards;
