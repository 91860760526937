import React from 'react';
import Google_review from '../assets/Google_Review_For_Website.png';
import KumarUjjvalReview from '../assets/vernacular_page/KumarUjjvalReview.png';
import SagarKumarReview from '../assets/vernacular_page/SagarKumarReview.png';
import DhirajKumarReview from '../assets/vernacular_page/DhirajKumarReview.png';

const Vernacular = () => {
  return (
    <div className='flex flex-col'>
    <div className='h-full w-[90%] lg:w-[85%] m-auto mt-24'>
      <div className='relative flex flex-col lg:flex-row-reverse items-center h-auto lg:h-96 xl:h-[500px] z-0'>
        
        {/* Image section */}
        <div className='flex justify-end lg:w-1/2 '>
          <img
            src="https://images.schooglink.com/image/website/phone_for_web.png"
            alt='app'
            className='z-5 mt-8 h-[350px] sm:h-[350px] md:h-[350px] lg:h-[350px] xl:h-[450px] object-cover'
          />
        </div>

        {/* Text section */}
        <div className='text-black z-5 mt-5 lg:mt-0 lg:w-1/2 px-2 sm:px-4 text-center lg:text-left'>
          <h1 className='text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold lg:ml-24 leading-tight'>
            Pathyacharya App
          </h1>
          <h2 className='text-md sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold lg:ml-24 mt-2'>
            The App to prepare for your Board Exams
          </h2>
          <p className='text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl lg:ml-24 mt-4' style={{ color: '#FF6F00' }}>
            अब हर घर में स्कूल
          </p>
          <br />
          <p className='text-sm sm:text-base md:text-lg lg:text-lg xl:text-xl lg:ml-24 leading-relaxed font-semibold text-gray-700'>
            Schooglink provides complete curriculum covering all subjects for grades Kindergarten to XII (K-12) across multiple State Boards, CBSE & NCERT, in multiple vernacular languages, through its smart App - Pathyacharya.
          </p>

          {/* Buttons */}
          <div className='flex flex-row mt-6 lg:ml-24 justify-center sm:justify-start space-x-4'>
            
            {/* Button 1: Download 
            <button
              className='bg-[#FF6F00] text-white py-2 px-6 w-full sm:w-auto rounded-lg text-sm sm:text-base font-semibold hover:bg-orange-600 transition duration-300'>
              Download
            </button>
            */}

            {/* Button 2: Google Play Image */}
            <div className='text-center flex flex-col sm:flex-row gap-4 justify-center items-center  mt-16'>
              <img 
                src={Google_review} 
                alt="google_review_for_website" 
                className='h-10 sm:h-12' 
              />
              <a
                href='https://play.google.com/store/apps/details?id=com.schooglink.curriculum.pathcharya'
                target='_blank'
                rel='noopener noreferrer'
                className='w-auto'
              >
                <img
                  src='https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg'
                  alt='Get it on Google Play'
                  className='h-10 sm:h-12'
                />
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="w-full flex flex-nowrap justify-start sm:justify-center gap-4 mt-16 overflow-x-auto">
  <img
    src={KumarUjjvalReview}
    alt="Kumar Ujjval Review"
    className="w-[90%] sm:w-1/3 md:w-1/4 lg:w-[28%] h-auto"
  />
  <img
    src={SagarKumarReview}
    alt="Sagar Kumar Review"
    className="w-[90%] sm:w-1/3 md:w-1/4 lg:w-[28%] h-auto"
  />
  <img
    src={DhirajKumarReview}
    alt="Dhiraj Kumar Review"
    className="w-[90%] sm:w-1/3 md:w-1/4 lg:w-[28%] h-auto"
  />
</div>
</div>
    </div>
  );
};

export default Vernacular;
