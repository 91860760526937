import React from 'react';
import schooglink_logo_white from '../assets/schooglink_logo_white.png';
import google_for_startup from '../assets/google_for_startup.png';
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="w-screen flex flex-col mt-20 text-white" style={{ backgroundColor: '#3c4149', padding: '40px 40px' }}>
      {/* Main Content: 3 Columns */}
      <div className="w-full flex flex-col md:flex-row justify-between lg:px-[142px]">
        {/* Left Side: Logo and Social Media */}
        <div className="flex flex-col items-start md:items-start md:w-1/3 mb-4 md:mb-0">
          {/* Logo */}
          <img src={schooglink_logo_white} alt="Schooglink" className="h-10 mb-4" />
          <img src={google_for_startup} alt="google_for_startups" className="h-12 mb-6" />
          
          {/* Social Media Links */}
          <p className='text-gray-200 my-4 text-sm'>Follow us on </p>
          <div className="flex space-x-6 mb-4">
            <a href="https://www.facebook.com/schooglink/"><FaFacebook className="text-xl" /></a>
            <a href="https://twitter.com/schooglink"><FaTwitter className="text-xl" /></a>
            <a href="https://www.linkedin.com/company/schooglink"><FaLinkedin className="text-xl" /></a>
            <a href="https://www.youtube.com/channel/UCgXdXVjxtBcKP_4ta_jZj9A"><FaYoutube className="text-xl" /></a>
          </div>
        </div>

        {/* Middle: Contact Information */}
        <div className="md:w-1/3 text-left mb-4 md:mb-0">
          <h2 className="text-md font-semibold mb-2">We love to hear from you!</h2>
          <p className="text-gray-400 text-sm mb-4 pr-4">
            Do you have any feedback about our products or any suggestions you would like to share with us?
          </p>
          <h3 className="font-semibold mb-1">Contact Us:</h3>
          <p className="text-gray-400 text-sm mb-1">+91 80695 10555</p>
          <p className="text-gray-400 text-sm">contact@schooglink.com</p>
        </div>

        {/* Right Side: Coordinates */}
        <div className="md:w-1/3 text-left">
          <h3 className="text-md font-semibold mb-2">Our Coordinates</h3>
          
          {/* Bengaluru */}
          <h1 className="text-lg font-semibold mt-4 mb-1">Bengaluru</h1>
          <p className="text-gray-400 text-sm">
            Ramasagara Village, Electronic City<br />
            Phase - 2, Bengaluru, Karnataka 560100
          </p>

          {/* Patna */}
          <h1 className="text-lg font-semibold mt-4 mb-1">Patna</h1>
          <p className="text-gray-400 text-sm">
            New Punaichak, Rajbanshi Nagar,<br />
            Patna, Bihar 800023
          </p>
        </div>
      </div>

      {/* Footer Bottom: Horizontal Menu */}
      <div className='w-full flex justify-start lg:justify-center items-center mt-8 pt-4'>
        <ul className='flex flex-col lg:flex-row w-full lg:w-auto gap-2 text-xs flex-wrap'>
          <li className='whitespace-nowrap hover:text-gray-300 mb-1 lg:mb-0 px-2 lg:px-8'>2024 - Schooglink Pvt Ltd, All Rights Reserved</li>
          <li className='mb-1 lg:mb-0 px-2 lg:px-8'><a href='sitemap' className='whitespace-nowrap hover:text-gray-300'>Site Map</a></li>
          <li className='mb-1 lg:mb-0 px-2 lg:px-8'><a href='aboutus' className='whitespace-nowrap hover:text-gray-300'>About Us</a></li>
          <li className='mb-1 lg:mb-0 px-2 lg:px-8'><a href='terms' className='whitespace-nowrap hover:text-gray-300'>Terms & Conditions</a></li>
          <li className='mb-1 lg:mb-0 px-2 lg:px-8'><a href='privacy' className='whitespace-nowrap hover:text-gray-300'>Privacy Policy</a></li>
          <li className='mb-1 lg:mb-0 px-2 lg:px-8'><a href='returns' className='whitespace-nowrap hover:text-gray-300'>Return Policy</a></li>
          <li className='mb-1 lg:mb-0 px-2 lg:px-8'><a href='contactus' className='whitespace-nowrap hover:text-gray-300'>Contact Us</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
