import React from 'react'

const Returns = () => {
  return (
    <div className='flex flex-col mt-24 px-12 w-[80%] mx-auto'>
        <h1 className='text-3xl text-center py-12'>REFUND and CANCELLATION POLICY</h1>
        <p align="justify">
	   <span>The terms "We" / "Us" / "Our" Company” individually and collectively refer to Schooglink Private Limited and the terms "You" /"Your" / "Yourself" refer to the Users.
	   </span>
	</p>
	<p align="justify">
	   <span>This REFUND and CANCELLATION POLICY is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information Technology Act, 2000. This REFUND and CANCELLATION POLICY does not require any physical, electronic or digital signature.
	   </span>
	</p>
	<p align="justify">
	   <span>This REFUND and CANCELLATION POLICY is a legally binding document between You and Us. The terms of this REFUND and CANCELLATION POLICY will be effective upon Your acceptance of the same (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the website or by other means) and will govern the relationship between You and Us for Your use of the website
	   <a href="https://www.schooglink.com/">https://www.schooglink.com/</a>
	   (“<strong>Website</strong>”).
	   </span>
	</p>
	<p align="justify">
	   <span>Please read this REFUND and CANCELLATION POLICY carefully before using the Website and purchasing any study or course material or such other services as a digital product or hard copy of such product from the Website
	   (to be referred to as ‘<strong>the</strong> <strong>Products’</strong>),
	   You indicate that You understand, agree and consent to this REFUND and CANCELLATION POLICY. If You do not agree with the terms of this REFUND and CANCELLATION POLICY, please do not purchase the Products from the Website.
	   </span>
	</p>
	<p align="justify">&nbsp;</p>
	<p align="justify">
	   <span>
	   <strong>REFUND TERMS:</strong>
	   </span>
	</p>
	<ol>
	   <li>
	      <span>The Products (digital products) which are downloadable, on license or accessible on a subscription basis or otherwise by such payment method as may be provided in the Website and upon such due payments as provided shall not be
	      <b>REFUNDABLE</b>
	      once the Product is downloaded, licensed or accessed. Every Product upon purchase shall be considered as downloaded, licensed or accessed by You unless proved otherwise.
	      </span>
	   </li>
	   <li>
	      <span>Such Products can only be refunded in the proven and established event of downloading error and/or corruption of Product etc. in the first instance.
	      </span>
	   </li>
	   <li>
	      <span>The Products in hard copy once received by the User shall not be refunded. The refund can be claimed only in the proven and established instance of non-delivery or wrong delivery of the Products.
	      </span>
	   </li>
	</ol>
	<p align="\&quot;justify\&quot;">
	   <span>
	   <strong>REFUND PROCEDURE:</strong>
	   </span>
	</p>
	<ol>
	   <li>
	      <span>The User shall send a request for refund ("Refund Request") of the payment received by the Company within five working days from the date of receipt of Products to the Grievance Officer (as defined below). The User must provide following details in the Refund Request:
	      </span>
	      <ul>
	         <li>
	            <span>The details of the transaction including transaction number, invoice, Product detail, date of purchase, proof of payment etc.
	            </span>
	         </li>
	         <li>
	            <span>A statement explaining how the User is entitled to Refund of payments with reasons of demand of refund, together with all documentary evidence in support of contesting the Refund.
	            </span>
	         </li>
	      </ul>
	   </li>
	</ol>
	<p align="justify">
	   <span>
	   <strong>REFUND AND SHIPPING:</strong>
	   </span>
	</p>
	<p align="justify">
	   <span>In case We are satisfied with the details of the Refund Request then We may at our sole discretion decide to either return such amount of payment after deduction of taxes, if any, or provide replacement of the Product with a new similar Product. You will be responsible for paying for your own shipping costs for returning the Product. Shipping costs are not refundable. If You proved to be entitled to a refund, We will deduct the cost of return shipment from Your approved amount of refund.
	   </span>
	</p>
	<br />
	<p align="justify">
	   <span>
	   <strong>CANCELLATION POLICY:</strong>
	   </span>
	</p>
	<span>There is no cancellation policy available at the time.</span>
	<br />
	<p align="justify">
	   <span>
	<h4>CUSTOMER CANCELLATION</h4>
	</span>
	</p>
	<span>Orders once billed cannot be cancelled.</span>
	<br />
	<p align="justify">
	   <span>
	<h4>OTHER CANCELLATIONS</h4>
	</span>
	</p>
	<span>
	Schooglink also reserves the right to cancel any orders at any time.
	</span>

	<br />
	<p align="justify">
	   <span>
	   <strong>GRIEVANCE REDRESSAL:</strong>
	   </span>
	</p>
	<p align="justify">
	   <span>Any Refund Request shall be addressed to the designated officer
	   (<strong>‘Grievance Officer’</strong>)
	   as mentioned below via post or through registered email to&nbsp;below Grievance Officer.
	   </span>
	</p>
	<p>
	   <span>
	   <strong>Name :&nbsp;&nbsp;Himanshu Agrawal<br/> </strong>
	   </span>
	   <strong>
	   <span>Email-id:
	   </span>
	   </strong>&nbsp;
	   <span>
	   <strong>&nbsp;support@schooglink.com</strong>
	   </span>
	</p>
	<p>
	   <span>
	   <strong>Postal Address:</strong>
	   </span>
	   <span>&nbsp;&nbsp;Schooglink Private Limited , Shree Niwas, New Punai Chak, Patna, Bihar
	   </span>
	</p>

    </div>
  )
}

export default Returns