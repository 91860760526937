import React, { useState, useEffect} from 'react';
import { pdfjs } from 'react-pdf';
// import { Document, Page } from 'react-pdf';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

// import Faq from "./Faq";
// import { faqlist1 } from "./FaqList1";
// import { faqlist2 } from "./FaqList2";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import image1 from '../assets/Image-1.jpg';
import image2 from '../assets/Image-2.jpg';
import image3 from '../assets/Image-3.jpg';
import image4 from '../assets/Image-4.jpg';
import image5 from '../assets/Image-5.jpg';
import scroll_1 from '../assets/grassroots/scroll_1.png';
import scroll_2 from '../assets/grassroots/scroll_2.png';
import scroll_3 from '../assets/grassroots/scroll_3.png';
import scroll_4 from '../assets/grassroots/scroll_4.png';
import scroll_5 from '../assets/grassroots/scroll_5.png';
import Astha from '../assets/grassroots/Astha.png';
import rishika from '../assets/grassroots/rishika.png';
import Priyanka from '../assets/grassroots/Priyanka.png';


  const toppers = [
    {
      name: "Astha Omar",
      location: "UPMSP (U.P. Board) 2023 ",
      score: "Class XII | Aggregate: 93%",
      sub1 : "Chemistry: 97/100",
      sub2 :"Mathematics: 96/100",
      image: Astha
    },
    {
      name: "Rishika Sinha",
      location: "BSEB (Bihar Board) 2023 ",
      score: "Class X | Aggregate: 94.4% ",
      sub1 : " Science: 96/100",
      sub2 :"Mathematics: 100/100",
      image: rishika
    },
    {
      name: "Priyanka Kumari ",
      location: "CBSE (Jharkhand) 2024 ",
      score: "Class X | Aggregate: 91%",
      sub1 : "Science: 95 | Mathematics: 91",
      sub2 :"English: 92 | Hindi: 92 | SST: 90",
      image: Priyanka
    }
  ];

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Grassroots = () => {
  
    // const [cards1] = useState(faqlist1);
    // const [cards2] = useState(faqlist2);
    // const [nav, setNav] = useState(false);
    // const handleNav = () => {
    //     setNav(!nav);
    // };

    // const [numPages, setNumPages] = useState();
    // const [pageNumber, setPageNumber] = useState(1);

    // function onDocumentLoadSuccess({ numPages }) {
    //   setNumPages(numPages);
    // }

    const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const images = [scroll_1, scroll_2, scroll_3, scroll_4, scroll_5];

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Touch event handlers
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
    setIsTouching(true);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      // Swipe left
      slideRight();
    } else if (touchStart - touchEnd < -50) {
      // Swipe right
      slideLeft();
    }
    setIsTouching(false);
  };

  // Slide functions
  const slideRight = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const slideLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  // Button click handlers, checking if a touch is in progress
  const handleSlideLeftClick = () => {
    if (!isTouching) {
      slideLeft();
    }
  };

  const handleSlideRightClick = () => {
    if (!isTouching) {
      slideRight();
    }
  };

  // Function to handle dot click
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };
  
  const slideRight2 = () => {
    var slider = document.getElementById('slider2');
    slider.scrollLeft += slider.clientWidth; // Scroll by full width of the slider
  }
  
  const slideLeft2 = () => {
    var slider = document.getElementById('slider2');
    slider.scrollLeft -= slider.clientWidth; // Scroll by full width of the slider
  }
  

// const SimpleImageSlider = () => {
//   // Array of image sources
//   const images = [image1, image2, image3, image4, image5];
  
//   // State to track the current image index
//   const [currentIndex, setCurrentIndex] = useState(0);

//   // Function to go to the next image
//   const nextImage = () => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//   };

//   // Function to go to the previous image
//   const prevImage = () => {
//       setCurrentIndex((prevIndex) =>
//           (prevIndex - 1 + images.length) % images.length
//       );
//   };}

    return (
        <div className='px-4 py-24'>
            <div className='flex flex-col justify-center items-center'>
                <h1 className='text-3xl font-extrabold'> स्कूग्लिंक ग्रासरूट्स सेंटर्स </h1>
            </div>
            <div className='relative mt-12 lg:w-[80%] w-[90%] rounded-lg overflow-hidden mx-auto'>
    {/* Image with text overlay for smaller and larger screens */}
    <div className='relative w-full h-[280px] sm:h-[350px] md:h-[400px] lg:h-[500px]'>
        <img 
            src="https://images.schooglink.com/image/website/grassroots.png" 
            alt='Grassroots' 
            className='w-full h-full object-cover' 
        />
        <div className="absolute inset-0 bg-teal-400 opacity-10 "></div>

        {/* Text overlay for both screen sizes */}
        <div className='absolute inset-0 bg-black/60 flex flex-col justify-center items-center lg:items-start '>
            <h1 className='text-xl text-white sm:text-2xl md:text-3xl lg:text-4xl px-4 py-4 font-extrabold lg:ml-24'>
                सक्षम भारत की नींव
            </h1>
            <p className='text-sm sm:text-base md:text-lg lg:text-xl px-4 text-white mt-8 lg:max-w-[45%] lg:ml-24'>
                2018 से स्कूग्लिंक ग्रासरूट्स स्मार्ट क्लासरूम ने भारत के कोने - कोने 
                में शिक्षा जगत में बदलाव की शुरुआत देखी है जहाँ छात्रों के साथ साथ 
                युवाओं को भी अपने करियर में आगे बढ़ने का सुनहरा मौका मिला है।
            </p>
        </div>
    </div>

    {/* Buttons go below the image for smaller screens and on the image for larger screens */}
    <div className='flex flex-row lg:absolute lg:inset-0 lg:flex-row gap-4 px-4 py-6 lg:py-20 lg:justify-start lg:items-center lg:ml-24 lg:mt-[350px] sm:static'>
        <a href='contactus'>
            <button className='bg-white text-black px-8 py-2 rounded-[10px] font-bold border border-black '>
               Enquiry
            </button>
        </a>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSce5DMFP03mO7TkNZgsFMkTB4m04Oeea5rq6Ufqb7q58NsPxA/viewform?usp=sf_link"
          target="_blank" // Open in a new tab
          rel="noopener noreferrer" // Security best practice
        >
         <button className='bg-green-600 text-white px-8 py-2 rounded-[10px] font-bold border border-black'>
           ग्रासरूट्स शिक्षक बनें
        </button>
        </a>

    </div>
</div>


<div className="px-4 py-12">
  <section className="max-w-5xl mx-auto border border-gray-400 rounded-lg">
    <div className="flex flex-col lg:flex-row gap-8">
      
      {/* YouTube Video Section on the Left */}
      <div className="flex-1 flex justify-center items-center h-auto p-2">
        <div className="w-full max-w-full relative" style={{ paddingBottom: '56.25%' }}>
          <iframe
            src="https://www.youtube.com/embed/a2mlPTYfRAQ"
            title="Schooglink Grassroots - Concept है सरल !"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            className="absolute top-0 left-0 w-full h-full border-r border-gray-400 rounded-lg"
          ></iframe>
        </div>
      </div>

      {/* List Section on the Right */}
      <div className="flex-1 flex flex-col justify-center h-auto px-6 sm:px-6 lg:px-0">
        <ul className="list-none space-y-[-4%] text-sm sm:text-base md:text-lg font-bold text-gray-900">
          {[
            "स्मार्ट क्लास रूम कोचिंग सेंटर",
            "100% पास परसेंटेज",
            "कांसेप्ट की सरलता पर फोकस",
            "एक्सपेरिमेंट के साथ पढ़ें",
            "साप्ताहिक टेस्ट एवं टेस्ट सीरीज",
            "ऑफलाइन एवं 24x7 ऑनलाइन सपोर्ट",
            "ग्रामीण भारत में सबसे पहली उपस्थिति।",
            "वोकेशनल कोर्स",
          ].map((item, index) => (
            <li key={index} className="relative pl-6">
              {/* Dot styling */}
              <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-3 h-3 bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-200 rounded-full"></span>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
</div>




            <div className='flex flex-col gap-8 justify-center items-center w-[80%] mx-auto'>
              <p className='font-bold text-center'>
                ऑनलाइन - ऑफलाइन हाइब्रिड मॉडल क्लासरूम के साथ भारत के कोने कोने तक उच्चतम शिक्षा पहुँचाता हुआ, 
                स्कूग्लिंक ग्रासरूट्स सेंटर 4 राज्यों में निरंतर विकास कर रहा है और भारत के अन्य क्षेत्रों तक पहुँचने के पथ पर अग्रसर है।
              </p>
            </div>


            <div className='flex flex-col gap-8 justify-center items-center px-4 py-8 mt-12'>
      <h1 className='justify-center font-bold text-2xl'> 
        स्कूग्लिंक ग्रासरूट्स की पहल
      </h1>
      <div className="py-8 flex flex-col items-center">
        <div
          className="w-full md:w-3/4 lg:w-1/2 overflow-hidden relative"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            id="image-slider"
            style={{
              display: 'flex',
              transform: `translateX(-${currentIndex * 80}%)`,
              transition: 'transform 0.5s ease',
            }}
          >
            {images.map((image, index) => (
              <div key={index} style={{ flex: '0 0 80%', marginRight: '32px' }}>
                <img
                  src={image}
                  alt={`Scroll${index + 1}`}
                  className="w-full h-auto"
                />
              </div>
            ))}
          </div>

          {/* Left Arrow for Desktop */}
          {!isMobile && (
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-2 rounded-full shadow-lg"
              onClick={handleSlideLeftClick}
            >
              <MdChevronLeft size={24} />
            </button>
          )}

          {/* Right Arrow for Desktop */}
          {!isMobile && (
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-2 rounded-full shadow-lg"
              onClick={handleSlideRightClick}
            >
              <MdChevronRight size={24} />
            </button>
          )}
        </div>

        {/* Dot Navigation */}
        <div className="flex justify-center gap-2 mt-4">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-3 h-3 rounded-full ${
                currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>


      {/* Toppers Section */}
      <div className="flex flex-col items-center mb-12 mt-8">
    <h2 className="text-3xl font-bold mb-8 text-center">होनहार</h2>
    <div className="flex flex-wrap justify-center overflow-hidden gap-6 py-4">
        {toppers.map((topper, index) => (
            <div
                key={index}
                className="flex flex-col items-center w-32 sm:w-40 md:w-48 lg:w-64 h-auto p-2"
            >
                <img
                    src={topper.image}
                    alt={topper.name}
                    className="w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 object-cover mb-2"
                />
                <h3 className="text-sm sm:text-base font-semibold text-center">{topper.name}</h3>
                <p className="text-xs sm:text-sm text-gray-600 text-center text-nowrap">{topper.location}</p>
                <p className="text-xs sm:text-sm text-gray-600 text-center text-nowrap">{topper.score}</p>
                <p className="text-xs sm:text-sm text-gray-600 text-center text-nowrap">{topper.sub1}</p>
                <p className="text-xs sm:text-sm text-gray-600 text-center text-nowrap">{topper.sub2}</p>
            </div>
        ))}
    </div>
</div>

           
            {/*
            <div className='flex flex-col gap-8 justify-center items-center px-4 py-8'>
                <h1 className='justify-center text-3xl font-bold'> 
                    हमारे सेंटर
                </h1>
            </div>
            <div>
                <div className='flex flex-wrap justify-center items-center gap-4 bg-gray-100 min-h-[400px] lg:min-h-[600px]'>
                    <img src="https://images.schooglink.com/image/website/india.png" alt='India' className='h-72 w-72 lg:h-96 lg:w-96' />
                    <div className='flex flex-col justify-center items-center gap-10'>
                        <div className='flex flex-row gap-10'>
                            <div className='bg-white/50 rounded-2xl p-2'>
                                <img src="https://images.schooglink.com/image/website/up.png" alt='Uttar Pradesh' className='h-32 w-32 lg:h-36 lg:w-52 rounded-lg' />
                            </div>
                            <div className='bg-white/50 rounded-2xl p-2'>
                                <img src="https://images.schooglink.com/image/website/mp.png" alt='Madhya Pradesh' className='h-32 w-32 lg:h-36 lg:w-52 rounded-lg' />
                            </div>
                        </div>
                        <div className='bg-white/50 rounded-2xl p-2'>
                            <img src="https://images.schooglink.com/image/website/jh.png" alt='Jharkhand' className='h-32 w-32 lg:h-36 lg:w-52 rounded-lg' />
                        </div>
                    </div>

                </div>
            </div>
            */}
            <div className="mt-12 px-4 py-4 lg:w-[80%] w-full  mx-auto">
                {/* Heading */}
                <h2 className="text-2xl font-bold text-center mb-12">स्कूग्लिंक ग्रासरूट्स का रंग बिरंगा वातावरण  </h2>

                {/* Image Row */}
                <div className="relative flex justify-center items-center ">
  {/* Left Chevron Button */}
  <MdChevronLeft onClick={slideLeft2} size={40} className="opacity-50 cursor-pointer hover:opacity-100" />

  {/* Scrollable Image Section */}
  <div id="slider2" className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide ">
    {/* Five Images */}
    <img src={image1} alt="1" className="w-72 h-52 object-cover inline-block p-2 rounded-xl border border-gray-300 cursor-pointer hover:scale-105 ease-in-out duration-300" />
    <img src={image2} alt="2" className="w-72 h-52 object-cover inline-block p-2 rounded-xl border border-gray-300 cursor-pointer hover:scale-105 ease-in-out duration-300" />
    <img src={image3} alt="3" className="w-72 h-52 object-cover inline-block p-2 rounded-xl border border-gray-300 cursor-pointer hover:scale-105 ease-in-out duration-300" />
    <img src={image4} alt="4" className="w-72 h-52 object-cover inline-block p-2 rounded-xl border border-gray-300 cursor-pointer hover:scale-105 ease-in-out duration-300" />
    <img src={image5} alt="5" className="w-72 h-52 object-cover inline-block p-2 rounded-xl border border-gray-300 cursor-pointer hover:scale-105 ease-in-out duration-300" />
  </div>

  {/* Right Chevron Button */}
  <MdChevronRight onClick={slideRight2} size={40} className="opacity-50 cursor-pointer hover:opacity-100" />
</div>
            </div>

            
            <div className='flex flex-col gap-8 justify-center items-center px-4 py-8 font-bold'>
                <h1 className='text-2xl font-bold text-center'> 
                    स्कूग्लिंक ग्रासरूट्स सेंटर्स एवं टीचर्स की अधिक जानकारी के लिए हमें संपर्क करें
                </h1>

                <div className='flex flex-wrap gap-12 lg:gap-24 justify-center items-start '>
                <div className='flex flex-col items-center text-center  px-10 py-5 rounded-xl'>
                        <h1 className='text-xl font-bold mb-2'>ग्रासरूट्स शिक्षक बनें</h1>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-2'>
                            <FaPhone className='text-blue-600' /> 
                            <FaWhatsapp className='text-green-600' />
                            <span>+91 62039 68604</span>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-4'>
                            <MdOutlineEmail className='text-red-600' /> 
                            <span>contact@schooglink.com</span>
                        </div>
                        <a
                           href="https://docs.google.com/forms/d/e/1FAIpQLSce5DMFP03mO7TkNZgsFMkTB4m04Oeea5rq6Ufqb7q58NsPxA/viewform?usp=sf_link"
                            target="_blank"
                           rel="noopener noreferrer"
                          >
                          <button className='border-blue-950 text-blue-950 py-2 px-4 rounded-md hover:bg-blue-700 hover:text-white transition'>
                            आवेदन पत्र भरें
                          </button>
                        </a>

                    </div>

                    <div className='flex flex-col items-center text-center px-10 py-5 rounded-xl'>
                        <h1 className='text-xl font-bold mb-2'>ग्रासरूट्स ट्यूशन सेंटर जॉइन करें</h1>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-4'>
                            <FaPhone className='text-blue-600' /> 
                            <span>+91 80695 10555</span>
                        </div>
                        <button className='border-green-700 text-green-900 py-2 px-4 rounded-md hover:bg-green-700 hover:text-white transition'>संपर्क करें</button>
                    </div>

                    <div className='flex flex-col items-center text-center  px-10 py-5 rounded-xl'>
                        <h1 className='text-xl font-bold mb-2'>अपने शहर में ग्रासरूट्स सेंटर खोलें</h1>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-2'>
                            <FaPhone className='text-blue-600' /> 
                            <FaWhatsapp className='text-green-600' />
                            <span>+91 62039 68604</span>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-4'>
                            <MdOutlineEmail className='text-red-600' /> 
                            <span>contact@schooglink.com</span>
                        </div>
                        <button className='border-orange-600 text-orange-700 py-2 px-4 rounded-md hover:bg-orange-600 hover:text-white transition'>संपर्क करें</button>
                    </div>
                </div>
            </div>
            {/* <div className="mt-16 text-center"> 
  <h2 className="text-4xl font-bold mb-4">ग्रासरूट्स शिक्षक बनें </h2> 
  <a
    href="https://docs.google.com/forms/d/e/1FAIpQLSce5DMFP03mO7TkNZgsFMkTB4m04Oeea5rq6Ufqb7q58NsPxA/viewform?usp=sf_link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <button className='bg-green-600 text-white text-2xl px-8 py-2 rounded-[10px] font-bold border border-black'>
     Apply Here
    </button>
  </a>
</div> */}

        </div>
    )
}

export default Grassroots
