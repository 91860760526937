import React from 'react'

const Terms = () => {
  return (
    <div className='flex flex-col mt-24 px-10'>
    <h1 className='text-4xl text-center'>Terms and condition</h1>
    <p align="justify"><span><strong>Last Updated on </strong></span><span><u><strong>20.08.2019</strong></u></span></p>
	<p align="justify"><span><strong>This document is an electronic record in terms of the Information Technology Act, 2000 and the rules framed there under. This electronic record is generated by a computer system and does not require any physical or digital signatures.</strong></span></p>
	<p align="justify"> <span lang="en-IN"><strong>These Terms of Use have been drafted in accordance with the provisions of Rule 3&nbsp;(1) of the Information Technology (Intermediaries guidelines) Rules,&nbsp;2011 that require publishing the rules and regulations, privacy policy and&nbsp;Terms of Use for access or usage of the Website or App. </strong></span></p>
	<ol>
	   <li>
	      <span lang="en-IN"><strong>About Us</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>The domain name</span><span>,</span><span>&nbsp;</span><a href="http://schooglink.com/"><span>http://schooglink.com/</span></a><span> (hereinafter referred to as "</span><span><strong>Website</strong></span><span>") and the application for mobile and handheld devices know as </span><span><u>Schooglink</u></span> <span>( the</span><span><strong> “App”)</strong></span> <span>owned and managed by </span><span>Schooglink Private Limited</span><span> (hereinafter referred to as “</span><span><strong>Company</strong></span><span>”</span><span> which expression shall mean and include its affiliates, successors and assigns</span><span>) shall hereafter collectively be referred to as the “</span><span><strong>Platform</strong></span><span>”. The Platform is an online communication and interactive platform that aims to create communication channels connecting various communities in the education sector, build a value added knowledge flow, provide for an aggregated view on the entire educational space and create a well segmented marketplace to feature key education supplements.</span></p>
	<p align="justify"><span>Please refer to the “</span><span><strong>About Us</strong></span><span>” section on the Website or App for further details.</span></p>
	<ol start="2">
	   <li>
	      <span lang="en-IN"><strong>Terms of Use </strong></span>
	   </li>
	</ol>
	<p align="justify"><span lang="en-IN">Your use of the Platform and services and tools are governed by the following&nbsp;terms and conditions (</span><span lang="en-IN"><strong>"Terms of Use"</strong></span><span lang="en-IN">) as applicable to the Platform including&nbsp;the applicable policies which are incorporated herein by way of reference. Please carefully read these Terms of Use. By mere </span><span>accessing, browsing or otherwise using the Website or installing the App, You indicate your acceptance to all the terms and conditions herein</span><span lang="en-IN">.</span></p>
	<p align="justify"><span lang="en-IN">For the purpose of these Terms of Use, wherever the context so requires&nbsp;</span><span lang="en-IN"><strong>"You"</strong></span><span lang="en-IN">&nbsp;or&nbsp;</span><span lang="en-IN"><strong>"User"</strong></span><span lang="en-IN">&nbsp;shall mean any natural or legal person who has agreed to become a user of&nbsp;the Platform by visiting the Platform or registering on the Platform. The term&nbsp;</span><span lang="en-IN"><strong>"We"</strong></span><span lang="en-IN">,&nbsp;</span><span lang="en-IN"><strong>"Us"</strong></span><span lang="en-IN">,&nbsp;</span><span lang="en-IN"><strong>"Our"</strong></span><span lang="en-IN">&nbsp;shall mean Company.</span></p>
	<p align="justify"><span>You hereby by way of accepting these Terms of Use consent to the receipt of communication from Us by way of e-mails and newsletters.</span></p>
	<ol start="3">
	   <li>
	      <span lang="en-IN"><strong>Eligibility</strong></span><span><strong> to use</strong></span>
	   </li>
	</ol>
	<p align="justify"><span lang="en-GB">You must be a natural person who is atleast 18 years of age. If You are less than 18 years of age, please read through these terms of use with your parent or legal guardian, and in such a case these terms of use shall be deemed to be a contract between the company and your legal guardian or parent and to the extent permissible under applicable laws, enforceable against You.</span></p>
	<p lang="en-GB" align="justify">&nbsp;</p>
	<p align="justify"><span>Company reserves the right to refuse access to use Platform to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.</span></p>
	<ol start="4">
	   <li>
	      <span lang="en-IN"><strong>Your</strong></span><span><strong> use of Company Platform</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>The Company offers access to the Platform to different categories of individuals such as parents, students, teachers, third party vendors and such other categories of persons as included by the Company from time to time. Users under each category are required to register themselves on the Platform and make an individual account (“</span><span><strong>Account</strong></span><span>”) in order to access and use the Platform. As part of the Account registration, You will be required to provide an e-mail address and a password which is to be used for logging into the Account in the future. The right to use the Platform is personal to the User and is not transferable to any other person or entity. The said username and password should be kept highly confidential and should not be disclosed to any third party. You agree to accept responsibility for all activities that occur under your Account. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform Us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be used in an unauthorized manner. </span></p>
	<p align="justify"><span>You shall ensure that the Account information provided by You in the Platform registration form is complete, accurate and up-to-date. Use of another User's Account information is expressly prohibited.</span></p>
	<p align="justify"><span>If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Company has the right to suspend or terminate your Account and refuse any and all current or future use of the Platform (or any portion thereof).</span></p>
	<ol start="5">
	   <li>
	      <span lang="en-IN"><strong>Privacy</strong></span><span><strong> and Information Protection</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>Please review our Privacy Policy (available at </span><span><a href="https://schooglink.com/privacy-policy">https://schooglink.com/privacy-policy</a></span><span>), which also governs your visit to this Platform, to understand our internal policies and practices. The personal information / data provided to Us by You during the course of usage of </span><span>Platform</span> <span>will be treated as strictly confidential and in accordance with the Privacy Policy and applicable laws and regulations. If You object to your information being transferred or used, please do not use the Platform. </span></p>
	<ol start="6">
	   <li>
	      <span lang="en-IN"><strong>Rights</strong></span><span><strong> of use the Platform</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>Company solely and exclusively owns all intellectual property rights in the Platform and the contents therein and grants You limited rights to access and make personal use of this Platform, but not to download (other than page caching) or modify it, or any portion of it. These rights do not include any commercial use of this Platform or its contents without the express consent of the Company; any collection and use of any content, descriptions, or prices; any derivative use of this Platform or its contents; any downloading or copying of account information for the benefit of a third-party; or any use of data mining, robots, or similar data gathering and extraction tools.</span></p>
	<p align="justify"><span>This Platform or any portion of this Platform (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose.</span></p>
	<ol start="7">
	   <li>
	      <span><strong>Your Conduct</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>You must not use the Platform in any way that causes, or is likely to cause, the Platform or access to it to be interrupted, damaged or impaired in any way. You understand that You, and not Company, are responsible for all electronic communications and content sent from Your Account/computer to Us and You must use the Platform for lawful purposes only. You must not use the Platform for any of the following: </span></p>
	<ul>
	   <li>
	      <span>for fraudulent purposes, or in connection with a criminal offense or other unlawful activity </span>
	   </li>
	   <li>
	      <span>to send, use or reuse any material that does not belong to You; </span>
	   </li>
	   <li>
	      <span>to upload or post illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging content;</span>
	   </li>
	   <li>
	      <span>to impersonate another person; </span>
	   </li>
	   <li>
	      <span>to threaten the unity, integrity, security or sovereignty of India or friendly relations with foreign States; </span>
	   </li>
	   <li>
	      <span>to upload or post content that is objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam”. </span>
	   </li>
	   <li>
	      <span>to cause annoyance, inconvenience or needless anxiety. </span>
	   </li>
	   <li>
	      <span>to upload content that infringes upon or violates any third party’s rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person’s name, e-mail address, physical address or phone number) or rights of publicity.</span>
	   </li>
	   <li>
	      <span>to gain unauthorized access or exceed the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, or other areas of the Platform or solicits passwords or personally identifying information for commercial or unlawful purposes from other Users of the Platform.</span>
	   </li>
	   <li>
	      <span>to engage in commercial activities without the Company’s prior written consent such as engage in contests, advertising etc.</span>
	   </li>
	   <li>
	      <span>to interfere with another User’s use of the Platform.</span>
	   </li>
	   <li>
	      <span>to refer to any website or URL that, in the Company’s sole discretion, contains material that is inappropriate for the Platform or that would be prohibited or violates the letter or spirit of these Terms of Use.</span>
	   </li>
	   <li>
	      <span>to deceive or mislead the Users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature.</span>
	   </li>
	   <li>
	      <span>to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, diminish value of, covertly intercept or steal any system, data or personal information.</span>
	   </li>
	</ul>
	<ol start="8">
	   <li>
	      <span lang="en-IN"><strong>Profanity</strong></span><strong><span> Policy</span></strong>
	   </li>
	</ol>
	<p align="justify"><span>Our Platform prohibits the use of language that is racist, hateful, sexual or obscene in&nbsp;nature in any form whatsoever.</span></p>
	<p align="justify"><span>Disciplinary action may result in the indefinite suspension of a User's Account,&nbsp;temporary suspension, or a formal warning.</span></p>
	<ol start="9">
	   <li>
	      <strong><span>Links to&nbsp;Third Party Websites</span></strong>
	   </li>
	</ol>
	<p align="justify"><span>The Platform may contain links and interactive functionality with the websites of third parties. </span><span lang="en-IN">Third party websites may be governed by a privacy policy and terms of use different from that of the Company</span><span> and the Company is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such website or any of the content, products and services provided on such linked websites. The Company</span><span lang="en-IN"> does not endorse the linked websites or warrant or make any representations regarding the correctness or accuracy of the content on such websites.</span> <span lang="en-IN">If You decide to access such linked websites, You do so at its own risk. </span><span>Before enabling any sharing functions to communicate with any such website or otherwise visiting any such website, the Company strongly recommends that You review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.</span></p>
	<ol start="10">
	   <li>
	      <p align="justify"><strong><span>User Content</span></strong></p>
	   </li>
	</ol>
	<p align="justify"><span>The Platform may allow You to post certain content, data or information of your own, such as allowing You to chat with other Users on the chat engine available on the Platform, or share your experience and views about a particular post, post your comments and reviews in relation to contents on the Platform on specific pages of the Platform, as well as submit any suggestions, comments, questions or other information to Company through the Platform (collectively referred to “</span><strong><span>User Content</span></strong><span>”). You are solely and entirely responsible for any such User Content that You upload, post, publish, transmit or otherwise make available on the Platform.</span></p>
	<p align="justify"><span>You hereby grant the Company a perpetual, non-revocable, worldwide, royalty-free and sub-licensable right and license to use, copy, distribute, display, publish, transmit, make available, reproduce, modify, adapt the User Content and create derivate works of the User Content. </span></p>
	<p align="justify"><span>You represent and warrant that (a) You have all of the rights, title, and interest in the User Content necessary to grant any and all licenses for all User Content,; (b) no portion of your User Content is false, misleading, pornographic, defamatory, libelous, slanderous, illegal, or threatening; (c) You will not engage in any illegal activities; (d) the User Content and your act to putting up the User Content on the Platform does and will comply with all applicable laws, rules, and regulations and will not infringe the rights of any third party, including any privacy or intellectual property rights; (e) Your User Content does not harm minors in any way or is otherwise threatening or promotes violence, actions that are threatening to any person or otherwise promotes an illegal activity; and (f) You have not added to your User Content any tags, gifs, beacons or other technology that allows You to collect information through your User Content; (g) Your User Content is accurate;</span></p>
	<ol start="11">
	   <li>
	      <span lang="en-IN"><strong>Disclaimer</strong></span><span><strong> of Warranties and Liabilities</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>We expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the information, content (including product information and/or specifications), materials included on the Platform. While We have taken precautions to avoid inaccuracies in content, this Platform, all content, information, software, products, services and related graphics are provided on an “as is” and “as available” basis, without warranty of any kind. </span></p>
	<ol start="12">
	   <li>
	      <span lang="en-IN"><strong>Indemnity</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>You shall indemnify and hold harmless Company, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms of Use, Privacy Policy or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party. </span></p>
	<ol start="13">
	   <li>
	      <span lang="en-IN"><strong>Limitation of Liability</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>You hereby expressly release Company and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waiver any claims or demands that You may have in this behalf under any statute, contract or otherwise. </span></p>
	<p align="justify"><span>You further acknowledge that the Company shall not be liable to You for any special, consequential, incidental, and exemplary or punitive damages, or loss of profit or revenues. We are also not liable under any circumstances for damages arising out or related in any way to your inability to access, or your difficulty in accessing the Platform, any bugs, viruses, trojan horses, or the like, which may be transmitted to or through the Platform by any third party, any loss of your data or content from the services, your failure to keep your password or Account details secure and confidential. </span></p>
	<p align="justify"><span>We shall not be liable under any circumstances for damages arising out of or in any way related to products, services and/or information offered or provided by third-party vendors accessed through the Platform.</span></p>
	<ol start="14">
	   <li>
	      <span lang="en-IN"><strong>Termination</strong></span>
	   </li>
	</ol>
	<p align="justify"><span lang="en-IN">In the event it is determined by Us that You have violated any of these Terms of Use, We shall have the right, at Our sole discretion, to suspend your use of and prohibit access to any or all features / parts of the Platform forthwith. Any such suspension or termination of access to Platform may be affected by Us without providing You with a prior written notice in this regard. Upon such termination of access to the Platform, these Terms of Use, as applicable to You, will be deemed to have been terminated, with the exception of those clauses that expressly provide otherwise.</span></p>
	<ol start="15">
	   <li>
	      <span lang="en-IN"><strong>Alteration of Service or Amendments to the</strong></span><span><strong> Conditions</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>We reserve the right to make changes to our Website, App, policies, and these Terms of Use at any time. You will be subject to the policies and Terms of Use in force at the time that You use the Platform, unless any change to those policies or these conditions is required to be made by law or government authority. </span></p>
	<ol start="16">
	   <li>
	      <span lang="en-IN"><strong>Waiver</strong></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span lang="en-US">The rights and remedies available under this Terms of Use may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.</span></p>
	<ol start="17">
	   <li>
	      <span lang="en-IN"><strong>Governing</strong></span><span><strong> law and Jurisdiction</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>These Terms of Use are governed by and construed in accordance with the laws of India. You agree, as We do, to submit to the exclusive jurisdiction of the courts at Bangalore.</span></p>
	<ol start="18">
	   <li>
	      <span lang="en-IN"><strong>Severability</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>If any term, provision, covenant or restriction of these Terms of Use is held by a court of competent jurisdiction to be invalid, void or unenforceable, the remainder of the terms, provisions, covenants and restrictions of these Terms of Use shall remain in full force and effect and shall in no way be affected, impaired or invalidated.</span></p>
	<ol start="19">
	   <li>
	      <span lang="en-IN"><strong>Contact</strong></span><span><strong> Information</strong></span>
	   </li>
	</ol>
	<p align="justify"><span>If You have any have any question, issue, concerns regarding any of our Platform, please contact at </span><span>contact@schooglink.com.</span></p>



    </div>
  )
}

export default Terms